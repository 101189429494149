<template>
  <div ref="menuMainBtnRef" class="group/header-menu-link relative">
    <component
      :is="component ?? (outLink ? 'a' : 'router-link')"
      :to="outLink ? '#' : linkTo || '#'"
      :href="outLink ? linkTo || '#' : '#'"
      :target="outLink ? '_blank' : 'none'"
      class="larken flex w-full cursor-pointer items-center py-2.5 leading-tight text-white/50 !opacity-100 hover:bg-[#C99D291A] hover:!text-white md:px-[16px]"
      :class="[
        statusData.selected ? '!text-[#ECBA33] md:text-white' : '',
        isSubLink
          ? 'px-[20px] text-base'
          : 'min-h-11 px-[16px] text-base md:min-h-[50px] md:text-xl',
        $slots.default
          ? expandedOnMobile
            ? 'max-md:bg-[#C99D291A]'
            : 'md:group-hover/header-menu-link:text-[#ECBA33]'
          : '',
        mainBtnClass,
      ]"
    >
      <div class="flex w-full items-center justify-between gap-1 md:relative">
        <slot v-if="$slots.icon" name="icon" />
        <span v-if="statusData.selectedTitle || title" class="text-nowrap">
          {{ statusData.selectedTitle || title }}</span
        >
        <span
          v-if="statusData.selected && !isSubLink && !hideStatusBar"
          class="pointer-events-none absolute left-0 rounded-full bg-[#ECBA33] md:top-full md:h-1 md:w-full md:translate-y-2"
          :class="
            statusBarBottomOnMobile
              ? 'max-md:top-full max-md:h-1 max-md:w-full max-md:-translate-y-1/2'
              : 'max-md:top-0 max-md:h-full max-md:w-1'
          "
        />
        <Icon
          v-if="$slots.default"
          class="ml-auto w-max text-xs text-white/50 transition-transform duration-300 md:group-hover/header-menu-link:rotate-180 md:group-hover/header-menu-link:text-white"
          :class="$slots.default && expandedOnMobile ? 'rotate-180' : ''"
          icon="icon-park-outline:down"
        />
      </div>
    </component>
    <div
      v-if="$slots.default"
      class="static right-0 top-full z-40 w-max min-w-full flex-col gap-1 pt-2.5 md:absolute md:hidden md:group-hover/header-menu-link:flex"
      :class="[
        !isPassiveMenu
          ? 'max-md:hidden max-md:group-hover/header-menu-link:flex'
          : expandedOnMobile
            ? 'max-md:flex'
            : 'max-md:hidden',
        subPanelClass,
      ]"
    >
      <div
        class="overflow-hidden rounded-[8px] bg-[#191919]"
        style="
          box-shadow:
            0px 966px 270px 0px rgba(102, 75, 0, 0),
            0px 618px 247px 0px rgba(102, 75, 0, 0.02),
            0px 348px 209px 0px rgba(102, 75, 0, 0.08),
            0px 155px 155px 0px rgba(102, 75, 0, 0.14),
            0px 39px 85px 0px rgba(102, 75, 0, 0.16),
            0px 4px 50px 0px rgba(0, 0, 0, 0.25);
        "
      >
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'nuxt/app'

interface HeaderLink {
  name: string
  link?: string
  out?: boolean
  subLinks?: HeaderLink[]
}

interface IProps {
  component?: string
  icon?: any
  title: string
  linkTo?: string
  isSubLink?: boolean
  subLinks?: HeaderLink[]
  outLink?: boolean
  subPanelClass?: string
  mainBtnClass?: string
  expandedOnMobile?: boolean
  isPassiveMenu?: boolean
  hideStatusBar?: boolean
  statusBarBottomOnMobile?: boolean
  action?: (arg?: any) => any
}
const { component, title, linkTo, outLink, isSubLink, subLinks, expandedOnMobile, isPassiveMenu } =
  defineProps<IProps>()

const route = useRoute()

const statusData = computed(() => {
  let selectedTitle
  let selected = false
  if (linkTo) {
    selected = route.path === linkTo || route.path === `/${linkTo}`
  } else if (subLinks && subLinks.length > 0) {
    for (const { link, name } of subLinks) {
      if (route.path === link || route.path === `/${link}`) {
        selectedTitle = name
        selected = true
      }
    }
  } else {
    selected = false
  }
  return { selected, selectedTitle }
})

const menuMainBtnRef = ref<HTMLElement | null>(null)
const emit = defineEmits<{
  toggleExpand: [MouseEvent]
  toggleCollapse: [MouseEvent]
}>()
const toggleExpandedOnMobile = (event: MouseEvent) => {
  if (
    !expandedOnMobile &&
    menuMainBtnRef.value &&
    !(menuMainBtnRef.value as HTMLElement).contains(event.target as Node)
  ) {
    emit('toggleCollapse', event)
  } else {
    emit('toggleExpand', event)
  }
}

onMounted(() => {
  window.addEventListener('click', toggleExpandedOnMobile)
})
onUnmounted(() => {
  window.removeEventListener('click', toggleExpandedOnMobile)
})
</script>
